import * as yup from 'yup';

export const tourBookingSchema = {
  duration: yup.number().required('required'),
  pickUp: yup
    .string()
    .trim()
    .required('required')
    .test(
      'duplicatedDropOff',
      'duplicatedPlace',
      (value: any, ctx: any) => value && value !== ctx.parent.dropOff,
    ),
  adults: yup
    .string()
    .trim()
    .required('required')
    .test(
      'isPositiveAdults',
      'onlyPositive',
      (value: any) => Number(value) > 0,
    ),
  // children: yup
  //   .string()
  //   .trim()
  //   .test(
  //     'isPositiveChildren',
  //     'onlyPositive',
  //     (value: any) => !value || Number(value) >= 0,
  //   ),
  note: yup.string().trim(),
};

export const tourBookingContactInfoSchema = {
  fullName: yup.string().trim().required('required'),
  // .matches(/^[a-zA-Z\s]+$/, 'fullNameInvalid'),
  phone: yup.string().trim().matches(/^\d*$/, 'phoneInvalid'),
  email: yup
    .string()
    .trim()
    .email('emailInvalid')
    .when('phone', {
      is: '',
      then: schema => schema.required('required'),
      otherwise: schema => schema,
    }),
  zalo: yup.string().trim().matches(/^\d*$/, 'zaloInvalid').nullable(),
  whatsapp: yup.string().trim().matches(/^\d*$/, 'whatsappInvalid').nullable(),
  kakaotalk: yup.string().trim(),
};

export const tourBookingCustomerSchema = {
  duration: yup.number().required('required'),
  pickUp: yup
    .string()
    .trim()
    .required('required')
    .test(
      'duplicatedDropOff',
      'duplicatedPlace',
      (value: any, ctx: any) => value && value !== ctx.parent.dropOff,
    ),
  adults: yup
    .string()
    .trim()
    .required('required')
    .test(
      'isPositiveAdults',
      'onlyPositive',
      (value: any) => Number(value) > 0,
    ),
  note: yup.string().trim(),
  fullName: yup.string().trim().required('required'),
  // .matches(/^[a-zA-Z\s]+$/, 'fullNameInvalid'),
  phone: yup.string().trim().matches(/^\d*$/, 'phoneInvalid'),
  email: yup
    .string()
    .trim()
    .email('emailInvalid')
    .when('phone', {
      is: '',
      then: schema => schema.required('required'),
      otherwise: schema => schema,
    }),
  zalo: yup.string().trim().matches(/^\d*$/, 'zaloInvalid').nullable(),
  whatsapp: yup.string().trim().matches(/^\d*$/, 'whatsappInvalid').nullable(),
  kakaotalk: yup.string().trim(),
};

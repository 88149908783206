import { IPickUp } from '@/types/interface';
import { Tour, TourType } from '@/types/tourBooking';
import {
  convertMinutesToDaysMinutes,
  formatMillisecondsToHours,
} from '@/utils/formatTime';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IPhoneData {
  createdAt?: string;
  createdBy?: string | null;
  description?: string | null;
  dialCode?: string;
  email?: string | null;
  fullName?: string;
  id?: string | null;
  isDeleted: boolean;
  orderBooked?: number;
  orderCompleted?: number;
  otp?: string | null;
  phone?: string | null;
  updatedAt?: string;
  updatedBy?: string | null;
  userId?: string | null;
}

const formatDate = 'DD/MM/YYYY';
const formatTime = 'HH:mm';

const validateBookingTime = (
  time: Moment,
  startTimeValue?: string,
  endTimeValue?: string,
) => {
  const bookingTime = moment(time, formatTime);
  const startTime = moment(startTimeValue ?? '07:00', formatTime);
  const endTime = moment(endTimeValue ?? '23:00', formatTime);

  return bookingTime.isBetween(startTime, endTime, null, '[)');
};

function validateReserveTime(
  futureDays: number,
  futureMinutes: number,
  time: Moment,
  date: Moment,
  tourType: TourType,
): boolean {
  const bookingTime = moment(time, formatTime);
  const bookingDateTime = moment(date, formatDate).set({
    hour: bookingTime.get('hour'),
    minute: bookingTime.get('minute'),
  });

  const currentTime = moment();
  const futureTime = moment()
    .add(futureDays, 'days')
    .add(futureMinutes, 'minutes');

  const isBookingFromFutureDay = bookingDateTime.isAfter(currentTime);

  // TODO: tour type: experience
  if (tourType && tourType === TourType.EXPERIENCE) {
    return isBookingFromFutureDay;
  }

  // TODO: tour type: adventures -> Book from future day
  else if (isBookingFromFutureDay) {
    return true;
  }

  // TODO: tour type: adventures -> Book in same day

  const currentTimeOnly = moment(moment(), formatTime);

  return bookingTime.isAfter(currentTimeOnly);
}

interface IUseTourBookingProps {
  service: any;
  tourType?: TourType;
  currentTour: Tour | null;
}

export default function useTourBooking({
  service,
  tourType,
  currentTour,
}: IUseTourBookingProps) {
  const { t } = useTranslation(['validate', 'error']);

  const [pickUpData, setPickUpData] = useState<IPickUp | null>(null);
  const [isChangedTimer, setIsChangedTimer] = useState<boolean>(false);
  const [date, setDate] = useState<Moment>(moment());
  const [dateErrMsg, setDateErrMsg] = useState<string>('');
  const [time, setTime] = useState<Moment>(moment().add(30, 'minutes'));
  const [timeErrMsg, setTimeErrMsg] = useState<string>('');
  const [successBooking, setSuccessBooking] = useState<boolean>(false);
  const [bookingErrMsg, setBookingErrMsg] = useState('');
  const [bookingDone, setBookingDone] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // check date is today or future
  const isCurrentDate = useMemo(() => {
    if (!isChangedTimer) return false;

    if (date) {
      return date.format(formatDate) === moment().format(formatDate);
    } else return false;
  }, [date, isChangedTimer]);

  useEffect(() => {
    if (tourType && tourType === TourType.EXPERIENCE) {
      setDate(moment().add(1, 'days'));
      return;
    }
    setDate(moment());
  }, [tourType]);

  // set error message for time, date inputs
  const setDateTimeErrMsg = useCallback(
    (validationError: string | null, type: 'date' | 'time') => {
      let text = '';
      if (validationError === 'invalidDate') {
        text = `${type}Invalid`;
      } else if (validationError === 'disablePast') {
        text = `${type}IsOver`;
      }

      type === 'date' ? setDateErrMsg(text) : setTimeErrMsg(text);
    },
    [],
  );

  const doSubmit = useCallback(async (body: any) => {
    setLoading(true);
    setBookingErrMsg('');
    setSuccessBooking(false);

    try {
      const { data } = await service.createBooking(body);
      if (data) {
        setBookingDone(data);
      }

      setSuccessBooking(true);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setBookingErrMsg(error?.response?.data?.message);
      } else setBookingErrMsg(`${t('common:failBooking')}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const doSubmitByPartner = useCallback(async (body: any) => {
    setLoading(true);
    setBookingErrMsg('');
    setSuccessBooking(false);

    try {
      const { data } = await service.createBookingByPartner(body);
      if (data) {
        setBookingDone(data);
      }

      setSuccessBooking(true);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setBookingErrMsg(error?.response?.data?.message);
      } else setBookingErrMsg(`${t('common:failBooking')}`);
    } finally {
      setLoading(false);
    }
  }, []);

  // check pickUp
  const checkPickUpAndDropOff = useCallback(
    (setValue: any, clearErrors: any) => {
      // if (!pickUpData) {
      //   setDistanceInformation({ km: 0, price: 0 });
      // }

      if (!!pickUpData) {
        clearErrors(['pickUp']);
      }
    },
    [pickUpData],
  );

  // validate date and time
  useEffect(() => {
    if (isCurrentDate && moment(time).isBefore(moment())) {
      setDateTimeErrMsg('disablePast', 'time');
    } else {
      setDateTimeErrMsg(null, 'time');
    }
  }, [isCurrentDate, time]);

  useEffect(() => {
    var msg = t('validate:timeInvalid');

    if (!!currentTour) {
      // Validate selected time after current time reverve time config in the tour.
      if (currentTour.reserveTime) {
        const { days, minutes } = convertMinutesToDaysMinutes(
          currentTour.reserveTime,
        );

        if (
          tourType &&
          !validateReserveTime(days, minutes, time, date, tourType)
        ) {
          var durationValue = '';
          var timeValue = '';

          if (days > 0) {
            durationValue = `${days}`;
            if (days > 1) {
              timeValue = ` ${t('common:days')} `;
            } else {
              timeValue = ` ${t('common:day')} `;
            }
          }

          if (minutes > 0) {
            if (durationValue !== '') {
              durationValue += `:${minutes}`;
            } else {
              durationValue = `${minutes}`;
            }

            if (timeValue === '') {
              if (minutes === 1) {
                timeValue = ` ${t('common:min')} `;
              } else {
                timeValue = ` ${t('common:mins')} `;
              }
            }
          }

          if (durationValue && timeValue) {
            const msg = t('validate:tourDate', {
              durationValue: durationValue,
              timeValue: timeValue,
            });
            setDateTimeErrMsg(msg, 'date');
            setTimeErrMsg(msg);
            return;
          }
        }
      }

      // Validate selected time is between start - end time if current tour have values start - end time
      if (currentTour.startActiveTime && currentTour.endActiveTime) {
        const fromTime = formatMillisecondsToHours(currentTour.startActiveTime);
        const toTime = formatMillisecondsToHours(currentTour.endActiveTime);

        if (!validateBookingTime(time, fromTime, toTime)) {
          msg = t('validate:tourTime', {
            fromTime: fromTime,
            toTime: toTime,
          });
          setDateTimeErrMsg('', 'date');
          setTimeErrMsg(msg);
          return;
        }
      }
    }
  }, [time, date]);

  return {
    pickUpData,
    setPickUpData,
    isChangedTimer,
    setIsChangedTimer,
    date,
    setDate,
    dateErrMsg,
    time,
    setTime,
    timeErrMsg,
    successBooking,
    setSuccessBooking,
    bookingErrMsg,
    bookingDone,
    loading,
    isCurrentDate,
    setDateTimeErrMsg,
    doSubmit,
    doSubmitByPartner,
    checkPickUpAndDropOff,
  };
}

import { IComputerPrice, ICreateBooking } from '@/types/interface';
import { apiClient } from './apiService';

export const paymentService = {
  getVNPayUrl(data: { id?: string; paymentType: string }) {
    return apiClient.request({
      method: 'POST',
      url: `/mobileCustomer/confirm_payment`,
      data,
    });
  },

  getVNPayUrlMobile(data: { id?: string; paymentType: string }) {
    return apiClient.request({
      method: 'POST',
      url: `/mobileCustomer/confirm_payment_mobile`,
      data,
    });
  },

  paymentSuccess(data: { id?: string }) {
    return apiClient.request({
      method: 'POST',
      url: `mobileCustomer/success_payment`,
      data,
    });
  },

  paymentSuccessMobile(data: { id?: string }) {
    return apiClient.request({
      method: 'POST',
      url: `mobileCustomer/success_payment_mobile`,
      data,
    });
  },
};

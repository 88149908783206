import { Tour, TourAdditionalService, TourDetail } from '@/types/tourBooking';
import moment, { Moment } from 'moment';
import React, {
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface TourBookingContextType {
  selectedTourIdForDetail: string | null;
  setSelectedTourIdForDetail: (id: string | null) => void;
  selectedTourIdForBooking: string | null;
  setSelectedTourIdForBooking: (id: string | null) => void;
  bookingTourData: TourBookingData;
  setBookingTourData: React.Dispatch<React.SetStateAction<TourBookingData>>;
  tourDetail: TourDetail | null;
  contactInfo: ContactInfoData;
  setContactInfo: React.Dispatch<React.SetStateAction<ContactInfoData>>;
  durationOptions: { label: string; value: number }[];
  services: TourAdditionalService[];
  locale?: string;
  tourPrice: number;
  servicesPrice: number;
  vatPrice: number;
  totalAmount: number;
  tourBookingDoneId: string | null;
  setTourBookingDoneId: (id: string | null) => void;
  selectedTour: Tour | null;
  tours: Tour[];
}

interface TourBookingData {
  duration: number;
  date: Moment;
  time: Moment;
  additionalServiceIds: string[];
  pickUp?: {
    lat: number;
    lng: number;
  };
  pickUpAddress: string;
  passengerNormal: number;
  passengerHalf?: number;
  note?: string;
}

interface ContactInfoData {
  fullName: string;
  phone: string;
  email: string;
  zalo?: string;
  whatsapp?: string;
  kakaotalk?: string;
}

export const TourBookingContext = createContext<
  TourBookingContextType | undefined
>(undefined);

interface TourBookingProviderProps {
  children: ReactNode;
  locale?: string;
  tours: Tour[];
}

const initTourBookingData: TourBookingData = {
  duration: 0,
  date: moment(),
  time: moment().add(30, 'minutes'),
  additionalServiceIds: [],
  pickUp: undefined,
  pickUpAddress: '',
  passengerNormal: 0,
  passengerHalf: undefined,
  note: undefined,
};

const initContactInfoData: ContactInfoData = {
  fullName: '',
  phone: '',
  email: '',
  zalo: undefined,
  whatsapp: undefined,
  kakaotalk: undefined,
};

export const convertTourDetailImages = (data: any): TourDetail => {
  return {
    ...data,
    images: JSON.parse(data.images) as string[], // Convert images to array
  };
};

export const TourBookingProvider: React.FC<TourBookingProviderProps> = ({
  children,
  locale,
  tours,
}) => {
  const [selectedTourIdForDetail, setSelectedTourIdForDetail] = useState<
    string | null
  >(null);
  const [selectedTourIdForBooking, setSelectedTourIdForBooking] = useState<
    string | null
  >(null);
  const [tourDetail, setTourDetail] = useState<TourDetail | null>(null);
  const [bookingTourData, setBookingTourData] =
    useState<TourBookingData>(initTourBookingData);
  const [contactInfo, setContactInfo] =
    useState<ContactInfoData>(initContactInfoData);
  const [tourBookingDoneId, setTourBookingDoneId] = useState<string | null>(
    null,
  );
  const [selectedTour, setSelectedTour] = useState<Tour | null>(null);

  const [durationOptions, setDurationOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [services, setServices] = useState<TourAdditionalService[]>([]);

  const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

  useEffect(() => {
    const foundTour = tours.find(t => t.id === selectedTourIdForBooking);
    if (foundTour) {
      setSelectedTour(foundTour);
      const formattedOptions = foundTour.tourPriceOptions?.map(option => ({
        label:
          option.duration.toString() +
          ` ${locale === 'vi' ? 'phút' : 'minutes'}`,
        value: option.duration,
      }));
      setDurationOptions(formattedOptions);
    }
  }, [locale, selectedTourIdForBooking, tours]);

  useEffect(() => {
    const fetchTourDetail = async () => {
      if (selectedTourIdForBooking && locale) {
        try {
          const res = await fetch(
            `${API_URL}booking_tour/${locale}/tour/${selectedTourIdForBooking}`,
          );
          if (!res.ok) {
            throw new Error(`Failed to fetch, status code: ${res.status}`);
          }
          const data = await res.json();
          const tour = convertTourDetailImages(data);
          setTourDetail(tour);
        } catch (error) {
          console.error('Error fetching tour detail:', error);
        }
      }
    };

    fetchTourDetail();
  }, [API_URL, locale, selectedTourIdForBooking]);

  useEffect(() => {
    const fetchTourAdditionalServices = async () => {
      if (!locale) return;
      if (selectedTour === null) return;

      try {
        const res = await fetch(
          `${API_URL}booking_tour/${locale}/additional_service?tourType=${selectedTour.tourType}`,
        );
        const data: TourAdditionalService[] = await res.json();
        setServices(data);
      } catch (error) {
        console.error('Error fetching tour additional services:', error);
      }
    };

    fetchTourAdditionalServices();
  }, [API_URL, locale, selectedTour]);

  const tourPrice = useMemo(() => {
    const foundPriceOptions = selectedTour?.tourPriceOptions.find(
      t => t.duration === bookingTourData.duration,
    );
    if (foundPriceOptions) {
      const adultsPrice =
        bookingTourData.passengerNormal * foundPriceOptions.pricePassenger;
      const childrenPrice =
        ((bookingTourData.passengerHalf ?? 0) *
          foundPriceOptions.pricePassenger) /
        2;
      if (adultsPrice + childrenPrice <= foundPriceOptions.minPrice) {
        return foundPriceOptions.minPrice;
      }
      return adultsPrice + childrenPrice;
    }
    return 0;
  }, [bookingTourData, selectedTour]);

  const servicesPrice = useMemo(() => {
    const serviceIds = bookingTourData.additionalServiceIds;
    let tempPrice = 0;
    services.forEach(s => {
      if (serviceIds.includes(s.id)) {
        tempPrice = tempPrice + s.price;
      }
    });

    return tempPrice;
  }, [bookingTourData.additionalServiceIds, services]);

  const vatPrice = useMemo(
    () => (tourPrice + servicesPrice) / 10,
    [servicesPrice, tourPrice],
  );

  const totalAmount = useMemo(
    () => tourPrice + servicesPrice,
    [servicesPrice, tourPrice],
  );

  return (
    <TourBookingContext.Provider
      value={{
        selectedTourIdForDetail,
        setSelectedTourIdForDetail,
        selectedTourIdForBooking,
        setSelectedTourIdForBooking,
        bookingTourData,
        setBookingTourData,
        tourDetail,
        contactInfo,
        setContactInfo,
        durationOptions,
        services,
        locale,
        tourPrice,
        servicesPrice,
        vatPrice,
        totalAmount,
        tourBookingDoneId,
        setTourBookingDoneId,
        selectedTour,
        tours,
      }}
    >
      {children}
    </TourBookingContext.Provider>
  );
};

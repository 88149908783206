import CheckboxTourBooking from '@/components/CheckboxTourBooking';
import {
  DatePickerCustom,
  TimePickerCustom,
} from '@/components/DateTimeCustom';
import InputAutocompleteClass from '@/components/InputAutocompleteClass';
import InputCustom from '@/components/InputCustom';
import MapDialog from '@/components/MapDialog';
import PassengersLimitModal from '@/components/PassengersLimitModal';
import PreLoading from '@/components/PreLoading';
import SelectFieldCustom from '@/components/SelectFieldCustom';
import { DepartureIcon, MapIcon } from '@/components/SvgIcons';
import TextFieldCustom from '@/components/TextFieldCustom';
import TourListItem from '@/components/tour-booking/TourListItem';
import { useTourBookingContext } from '@/containers/TourBookingByCustomer/providers/useTourBookingContext';
import useTourBooking from '@/hooks/useTourBooking';
import { bookingService } from '@/services/bookingService';
import { ITourBookingCustomerForm, ITourBookingForm } from '@/types/interface';
import { TourType } from '@/types/tourBooking';
import { tourBookingCustomerSchema } from '@/types/tourBookingSchema';
import { formatPriceWithNoUnit } from '@/utils/formatPrice';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  BookingDateTime,
  BookingForm,
  BookingNumberOfPeople,
  BookingPersonalInformation,
  BookingTripInformation,
  CardFooter,
  PersonalInfo,
} from './style';

type Props = {
  onSubmitBooking: () => void;
};

// Dữ liệu polyline
const polylineCoords = [
  { lat: 15.9209039, lng: 108.3248058 },
  { lat: 15.9106931, lng: 108.2997749 },
  { lat: 15.8997146, lng: 108.2938526 },
  { lat: 15.8881577, lng: 108.2885311 },
  { lat: 15.8763413, lng: 108.2991505 },
  { lat: 15.8745862, lng: 108.3173569 },
  { lat: 15.8663303, lng: 108.3337506 },
  { lat: 15.8684768, lng: 108.3457669 },
  { lat: 15.8650093, lng: 108.3499726 },
  { lat: 15.863647, lng: 108.3590062 },
  { lat: 15.8677338, lng: 108.3611306 },
  { lat: 15.8700455, lng: 108.3738335 },
  { lat: 15.8732247, lng: 108.3916092 },
  { lat: 15.8759896, lng: 108.3936604 },
  { lat: 15.8830068, lng: 108.390313 },
  { lat: 15.9236914, lng: 108.3272811 },
  { lat: 15.9209039, lng: 108.3248058 },
];

const formatDate = 'DD/MM/YYYY';
const formatTime = 'HH:mm';

export default function BookingTourForm({ onSubmitBooking }: Props) {
  const router = useRouter();

  const { t } = useTranslation('common');
  const {
    selectedTourIdForBooking,
    setSelectedTourIdForBooking,
    setBookingTourData,
    durationOptions,
    services,
    selectedTour,
    totalAmount,
    setContactInfo,
    tours,
  } = useTourBookingContext();
  const [typeMap, setTypeMap] = useState<'' | 'pickUp' | 'dropOff'>('');
  const [pickUpMapValue, setPickUpMapValue] = useState<string | null>(
    router.query.address as string | null,
  );
  const [selectedServiceIds, setSelectedServiceIds] = useState<string[]>([]);
  const [locationErr, setLocatitonErr] = useState<string | undefined>(
    undefined,
  );
  const [showPassengersModal, setShowPassengersModal] =
    useState<boolean>(false);

  const schema = useMemo(
    () =>
      yup
        .object()
        .shape(tourBookingCustomerSchema)
        .test(
          'at-least-one',
          'either phone or email is required',
          function (value) {
            const { phone, email } = value;
            if (!phone?.trim() && !email?.trim()) {
              return this.createError({ path: 'phone', message: 'required' });
            }
            return true;
          },
        ),
    [],
  );

  const {
    clearErrors,
    setValue,
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors },
  } = useForm<ITourBookingCustomerForm>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (durationOptions && durationOptions.length > 0) {
      setValue('duration', durationOptions[0].value);
    }
  }, [durationOptions, setValue]);

  const formValues = watch();

  const {
    pickUpData,
    setPickUpData,
    isChangedTimer,
    setIsChangedTimer,
    date,
    setDate,
    dateErrMsg,
    time,
    setTime,
    timeErrMsg,
    loading,
    isCurrentDate,
    setDateTimeErrMsg,
    checkPickUpAndDropOff,
  } = useTourBooking({
    service: bookingService,
    tourType: selectedTour?.tourType,
    currentTour: selectedTour,
  });

  useEffect(() => {
    checkPickUpAndDropOff(setValue, clearErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpData]);

  useEffect(() => {
    setBookingTourData(prevData => ({
      ...prevData,
      duration: formValues.duration,
      date: date,
      time: time,
      pickUpAddress: pickUpData?.pickUpAddress ?? '',
      pickUp: pickUpData?.pickUp ? JSON.parse(pickUpData?.pickUp) : undefined,
      passengerNormal: formValues.adults ? Number(formValues.adults) : 0,
      // passengerHalf: formValues.children ? Number(formValues.children) : 0,
      note: formValues.note,
      additionalServiceIds: selectedServiceIds,
    }));
  }, [
    date,
    formValues.adults,
    formValues.duration,
    formValues.note,
    pickUpData,
    selectedServiceIds,
    setBookingTourData,
    time,
  ]);

  useEffect(() => {
    setContactInfo(prevData => ({
      ...prevData,
      fullName: formValues.fullName,
      phone: formValues.phone,
      email: formValues.email,
      zalo: formValues.zalo,
      whatsapp: formValues.whatsapp,
      kakaotalk: formValues.kakaotalk,
    }));
  }, [
    formValues.email,
    formValues.fullName,
    formValues.kakaotalk,
    formValues.phone,
    formValues.whatsapp,
    formValues.zalo,
    setContactInfo,
  ]);

  useEffect(() => {
    if (router.query.address && router.query.lat && router.query.lng) {
      setPickUpData({
        pickUp: JSON.stringify({
          lat: Number(router.query.lat),
          lng: Number(router.query.lng),
        }),
        pickUpAddress: router.query.address as string,
      });
    }
  }, [router, setPickUpData]);

  useEffect(() => {
    if (selectedTourIdForBooking === null && tours.length > 0) {
      setSelectedTourIdForBooking(tours[0].id);

      return;
    }
  }, [selectedTourIdForBooking, setSelectedTourIdForBooking, tours]);

  const handleSelectService = (value: boolean, id: string) => {
    if (value) {
      setSelectedServiceIds(prevData => [...prevData, id]);
      return;
    }
    setSelectedServiceIds(prevData => prevData.filter(p => p !== id));
  };

  // validate location
  useEffect(() => {
    setLocatitonErr(undefined);
    if (pickUpData) {
      const polyline = new (window as any).google.maps.Polygon({
        paths: polylineCoords,
      });
      const pickUp = JSON.parse(pickUpData.pickUp ?? '');
      const pickUpLatLng = new (window as any).google.maps.LatLng(
        pickUp.lat,
        pickUp.lng,
      );

      if (
        !(window as any).google.maps.geometry.poly.containsLocation(
          pickUpLatLng,
          polyline,
        )
      ) {
        setLocatitonErr(t('validate:locationIsOutside') ?? '');
      }
    }
  }, [pickUpData, t]);

  const onSubmit = useCallback(
    (formData: ITourBookingForm) => {
      if (selectedTour === null) return;
      if (dateErrMsg || timeErrMsg) return;
      if (locationErr) return;

      if (Number(formData.adults) > selectedTour.passengerLimit) {
        setShowPassengersModal(true);
        return;
      }

      onSubmitBooking();
    },
    [selectedTour, dateErrMsg, timeErrMsg, pickUpData, onSubmitBooking, t],
  );

  return (
    <>
      <BookingForm
        className={selectedTour === null ? 'loading' : ''}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          minWidth: {
            xs: '0',
            md: '700px',
          },
        }}
      >
        {selectedTour !== null && selectedTour ? (
          <>
            <Box mb={4} px={3}>
              <TourListItem tour={selectedTour} isDetailedMode padding="0px" />
            </Box>

            <Grid
              container
              columnSpacing={{ xs: 0, md: 3.5 }}
              rowSpacing={{ xs: 6, md: 0 }}
              px={3}
              pb={2}
            >
              <Grid item xs={12} md={6}>
                <BookingTripInformation>
                  <Typography component="h3">
                    {t('common:tourBookingInfo')}
                  </Typography>

                  <Box>
                    {durationOptions &&
                      (selectedTour.tourType === TourType.EXPERIENCE ? (
                        <TextFieldCustom
                          size="small"
                          variant="filled"
                          key={selectedTour.id}
                          label={t('common:tourDuration')}
                          defaultValue={
                            durationOptions[0].value + ' ' + t('common:minutes')
                          }
                          fullWidth
                          inputProps={{
                            readOnly: true,
                          }}
                          helperText=" "
                        />
                      ) : (
                        <SelectFieldCustom
                          key={selectedTour.id}
                          label={t('common:tourDuration')}
                          options={durationOptions}
                          {...register('duration')}
                          error={Boolean(errors.duration)}
                          helperText={
                            errors.duration?.message
                              ? t(`validate:${errors.duration?.message}`)
                              : ' '
                          }
                          defaultValue={durationOptions[0].value}
                        />
                      ))}

                    <BookingDateTime>
                      <Box className="date-time">
                        <DatePickerCustom
                          label={t('common:selectDate')}
                          variant="filled"
                          format={formatDate}
                          value={date}
                          onChange={(val: any, ctx: any) => {
                            setIsChangedTimer(true);
                            setDateTimeErrMsg(ctx.validationError, 'date');
                            setDate(val);
                          }}
                          disablePast
                          helperText={
                            timeErrMsg || dateErrMsg ? undefined : ' '
                          }
                        />
                        <TimePickerCustom
                          label={t('common:selectTime')}
                          variant="filled"
                          ampm={false}
                          ampmInClock={false}
                          format={formatTime}
                          value={time}
                          onChange={(val: any, ctx: any) => {
                            setIsChangedTimer(true);
                            setDateTimeErrMsg(ctx.validationError, 'time');
                            setTime(val);
                          }}
                          disablePast={isCurrentDate}
                          error={!!timeErrMsg}
                        />
                      </Box>
                      {(timeErrMsg || dateErrMsg) && (
                        <p className="error-message MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled MuiFormHelperText-root">
                          {timeErrMsg === '' ? dateErrMsg : timeErrMsg}
                        </p>
                      )}
                    </BookingDateTime>
                  </Box>

                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography variant="h5" fontWeight={600}>
                      {t('common:additionalService')}
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={1}>
                      {services.map(s => (
                        <CheckboxTourBooking
                          key={s.id}
                          onChange={e =>
                            handleSelectService(e.currentTarget.checked, s.id)
                          }
                          label={
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography
                                variant="h4"
                                fontWeight={600}
                                flex={1}
                              >
                                {s.name}
                              </Typography>
                              <Typography variant="h4" fontWeight={400}>
                                {formatPriceWithNoUnit(s.price) + ' VNĐ'}
                              </Typography>
                            </Box>
                          }
                        />
                      ))}
                    </Box>
                  </Box>
                </BookingTripInformation>
              </Grid>

              <Grid item xs={12} md={6}>
                <BookingPersonalInformation>
                  <Typography component="h3">{t('common:tripInfo')}</Typography>
                  <Box>
                    <Box className="wrapper-input-button">
                      <InputAutocompleteClass
                        variant="filled"
                        isInitDefaultLocation={!router.query.address}
                        idText="pickUp"
                        label={
                          <Box display="flex" gap={0.5}>
                            {t('common:pickupLocation')!}{' '}
                            <Typography color={t => t.palette.error.main}>
                              *
                            </Typography>
                          </Box>
                        }
                        icon={<DepartureIcon />}
                        setOutput={setPickUpData}
                        setValue={(val: string, options?: any) =>
                          setValue('pickUp', val, { ...options })
                        }
                        mapValue={pickUpMapValue}
                        register={register('pickUp')}
                        error={Boolean(errors.pickUp) || Boolean(locationErr)}
                        helperText={
                          locationErr
                            ? locationErr
                            : errors.pickUp?.message
                              ? t(`validate:${errors.pickUp?.message}`)
                              : ' '
                        }
                      />
                      <IconButton onClick={() => setTypeMap('pickUp')}>
                        <MapIcon />
                      </IconButton>
                    </Box>

                    <BookingNumberOfPeople>
                      <InputCustom
                        variant="filled"
                        label={
                          <Box display="flex" gap={0.5}>
                            {t('common:adultGreaterThan2')!}{' '}
                            <Typography color={t => t.palette.error.main}>
                              *
                            </Typography>
                          </Box>
                        }
                        type="number"
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        autoComplete="off"
                        register={register('adults')}
                        error={Boolean(errors.adults)}
                        helperText={
                          errors.adults?.message
                            ? t(`validate:${errors.adults?.message}`)
                            : ' '
                        }
                        fullWidth
                      />
                    </BookingNumberOfPeople>
                  </Box>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography variant="h5" fontWeight={600}>
                      {t('common:notes')}
                    </Typography>
                    <TextFieldCustom
                      autoComplete="off"
                      placeholder={t('common:description')!}
                      {...register('note')}
                      multiline
                      rows={3}
                      sx={{
                        textarea: {
                          height: '80px !important',
                        },
                      }}
                      fullWidth
                    />
                  </Box>
                </BookingPersonalInformation>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  sx={{
                    paddingTop: {
                      xs: 0,
                      md: 3,
                    },
                  }}
                >
                  <Typography variant="h5" fontWeight={600}>
                    {t('common:passengerInfo')}
                  </Typography>
                  <PersonalInfo>
                    <Grid
                      container
                      columnSpacing={{ xs: 0, md: 3.5 }}
                      rowSpacing={{ xs: 1, md: 0 }}
                    >
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          size="small"
                          variant="filled"
                          label={
                            <Box display="flex" gap={0.5}>
                              {t('common:fullName')}{' '}
                              <Typography color={t => t.palette.error.main}>
                                *
                              </Typography>
                            </Box>
                          }
                          fullWidth
                          register={register('fullName')}
                          error={Boolean(errors.fullName)}
                          helperText={
                            errors.fullName?.message
                              ? t(`validate:${errors.fullName?.message}`)
                              : ' '
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          size="small"
                          variant="filled"
                          label={
                            <Box display="flex" gap={0.5}>
                              Email
                              {!formValues.phone && (
                                <Typography color={t => t.palette.error.main}>
                                  *
                                </Typography>
                              )}
                            </Box>
                          }
                          fullWidth
                          register={register('email')}
                          error={Boolean(errors.email)}
                          helperText={
                            errors.email?.message
                              ? t(`validate:${errors.email?.message}`)
                              : ' '
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      columnSpacing={{ xs: 0, md: 3.5 }}
                      rowSpacing={{ xs: 1, md: 0 }}
                    >
                      <Grid item xs={12} md={6}>
                        <Grid
                          container
                          columnSpacing={{ xs: 0, md: 2 }}
                          rowSpacing={{ xs: 1, md: 0 }}
                        >
                          <Grid item xs={12} md={6}>
                            <TextFieldCustom
                              size="small"
                              variant="filled"
                              label={
                                <Box display="flex" gap={0.5}>
                                  {t('common:phone')}{' '}
                                  {!formValues.email && (
                                    <Typography
                                      color={t => t.palette.error.main}
                                    >
                                      *
                                    </Typography>
                                  )}
                                </Box>
                              }
                              fullWidth
                              register={register('phone')}
                              error={Boolean(errors.phone)}
                              helperText={
                                errors.phone?.message
                                  ? t(`validate:${errors.phone?.message}`)
                                  : ' '
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextFieldCustom
                              size="small"
                              variant="filled"
                              label={'Zalo'}
                              fullWidth
                              register={register('zalo')}
                              error={Boolean(errors.zalo)}
                              helperText={
                                errors.zalo?.message
                                  ? t(`validate:${errors.zalo?.message}`)
                                  : ' '
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid
                          container
                          columnSpacing={{ xs: 0, md: 2 }}
                          rowSpacing={{ xs: 1, md: 0 }}
                        >
                          <Grid item xs={12} md={6}>
                            <TextFieldCustom
                              size="small"
                              variant="filled"
                              label={'Whatsapp'}
                              fullWidth
                              register={register('whatsapp')}
                              error={Boolean(errors.whatsapp)}
                              helperText={
                                errors.whatsapp?.message
                                  ? t(`validate:${errors.whatsapp?.message}`)
                                  : ' '
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextFieldCustom
                              size="small"
                              variant="filled"
                              label={'Kakaotalk'}
                              fullWidth
                              register={register('kakaotalk')}
                              helperText=" "
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </PersonalInfo>
                </Box>
              </Grid>
            </Grid>

            <CardFooter
              container
              columnSpacing={{ xs: 0, md: 3.5 }}
              rowSpacing={{ xs: 2, md: 0 }}
            >
              <Grid item xs={12} md={8}>
                <Box
                  display="flex"
                  gap={1}
                  sx={{
                    flexDirection: {
                      xs: 'row',
                      md: 'column',
                    },
                    justifyContent: {
                      xs: 'space-between',
                      md: 'center',
                    },
                    alignItems: {
                      xs: 'center',
                      md: 'flex-end',
                    },
                  }}
                >
                  <Typography variant="body4">
                    {t('common:totalAmount')}
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={600}
                    color={theme => theme.palette.hightlight.main}
                  >
                    {formatPriceWithNoUnit(totalAmount)} VNĐ
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" type="submit" fullWidth>
                    <Typography
                      fontWeight={600}
                      color={theme => theme.palette.white.main}
                    >
                      {t('common:bookTourNow')}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </CardFooter>
          </>
        ) : (
          <PreLoading />
        )}
      </BookingForm>

      <PassengersLimitModal
        isOpen={showPassengersModal}
        yesAction={() => setShowPassengersModal(false)}
        maxLimit={selectedTour?.passengerLimit ?? 0}
      />

      <MapDialog
        type={typeMap}
        onClose={() => setTypeMap('')}
        setMapValue={value => {
          if (typeMap === 'pickUp') setPickUpMapValue(value);
        }}
        pickUpData={pickUpData}
        setPickUpData={data => {
          setPickUpData(data);
        }}
      />
    </>
  );
}

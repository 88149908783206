/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Container } from './style';

type Props = {
  isOpen: boolean;
  yesAction: () => void;
  noAction: () => void;
  title: string;
  description: string;
};

export default function ConfirmModal({
  isOpen,
  yesAction,
  noAction,
  title,
  description,
}: Props) {
  const { t } = useTranslation('common');

  return (
    <Modal open={isOpen} onClose={noAction}>
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Typography variant="h2" fontWeight={600}>
            {title}
          </Typography>
          <Typography variant="h3">{description}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Button variant="outlined" onClick={noAction}>
            {t('common:skip')}
          </Button>
          <Button variant="contained" onClick={yesAction}>
            <Typography fontWeight={600} color={t => t.palette.white.main}>
              {t('common:agree')}
            </Typography>
          </Button>
        </Box>
      </Container>
    </Modal>
  );
}
